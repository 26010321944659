import React from "react";
import Button from "components/SharedComponents/Button";

const Home = () => {
  return (
    <div className="h-screen font-lemon bg-cover">
      <div className="flex justify-center items-center pt-20 md:pt-40">
        <img
          src="/assets/logo_plavi.png"
          className="w-80"
          alt="Sisni Me Logo"
        />
      </div>
      <h2 className="mt-10 text-white max-w-[350px] md:max-w-[650px] text-center mx-auto">
        Šišanje nikada nije bilo jednostavnije!
      </h2>
      <Button
        type="button"
        position="flex justify-center w-fit mx-auto mt-6"
        url="/login"
      >
        Log In
      </Button>
    </div>
  );
};

export default Home;
