
import React from 'react';

function VerifyAccountPage(token:string) {
  console.log(token)
  return (
    <div>
     Uspesno ste verifikovali nalog!
      
    </div>
  );
}

export default VerifyAccountPage;
