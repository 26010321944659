import React from "react";
import Button from "components/SharedComponents/Button";

const ErrorPage = () => {
  return (
    <div className="bg-gray-800 h-[100vh] text-center text-white flex flex-col justify-center items-center font-bold">
      <div className="text-3xl bg-gray-700 px-6 py-5 w-fit flex justify-center items-center rounded-lg">
        😱
      </div>
      <p className="mt-4 text-xl mx-4">
        Ups! Ova stranica nije dostupna, možda je trenutno na šišanju!
      </p>
      <Button type="button" position="mt-4" url="/">
        Početna strana
      </Button>
    </div>
  );
};

export default ErrorPage;
