import React from "react";
import Button from "./Button";
import { useLocation } from "react-router-dom";

interface Props {
  pageName?: string;
}

const AnonymusNavbar: React.FC<Props> = ({ pageName }) => {
  const location = useLocation();
  const toggleRegisterLogin =
    location.pathname === "/" || location.pathname === "/login";

  return (
    <div className="top-0 w-full bg-transparent font-lemon pt-8 flex flex-col md:flex-row gap-4 justify-between items-center px-10 lg:px-20">
      {pageName === "index" ? (
        <a
          href="/partner"
          className="text-primary text-lg transition 3s hover:scale-110 pt-4 uppercase"
        >
          postani partner
        </a>
      ) : (
        <a href="/" className="transition 3s hover:scale-110">
          <img
            src="/assets/logo_plavi.png"
            className="w-40"
            alt="Sisni Me Logo"
          />
        </a>
      )}
      {toggleRegisterLogin ? (
        <Button type="button" url="/napravi-nalog">
          Register
        </Button>
      ) : (
        <Button type="button" url="/login">
          Log In
        </Button>
      )}
    </div>
  );
};

export default AnonymusNavbar;
