import React, { useState } from "react";
import MiniBarberCard from "./MiniBarberCard";
import { Barber } from "./BarberTypes";

interface BarbersListProps {
  availableBarbers: Barber[];
  onClick: (barber: Barber) => void;
}

const BarbersList: React.FC<BarbersListProps> = ({
  onClick,
  availableBarbers,
}) => {
  const [searchBarber, setSearchBarber] = useState("");

  const matchesSearchTerm = (field: string) =>
    field?.toLowerCase().includes(searchBarber.toLowerCase());

  const isBarberMatching = (barber: Barber) =>
    matchesSearchTerm(barber?.ime) ||
    matchesSearchTerm(barber?.lokacija?.adresa) ||
    matchesSearchTerm(barber?.lokacija?.opstina) ||
    matchesSearchTerm(barber?.lokacija?.grad);

  const filteredBarbers = availableBarbers?.filter(isBarberMatching);

  return (
    <div className="mt-10 px-8 py-6 bg-gray-700 rounded-lg flex flex-col justify-center items-center">
      <div className="flex justify-center items-center">
        <div className="flex flex-col justify-center items-center relative">
          <input
            type="text"
            placeholder="Pretraži frizere"
            className="bg-gray-800 mb-4 w-1/2 p-2 rounded focus:outline-none focus:ring-1 focus:ring-primary text-white"
            value={searchBarber}
            onChange={(e) => setSearchBarber(e.target.value)}
          />
          {filteredBarbers.length !== 0 ? (
            <div className="md:grid md:grid-cols-2 flex flex-col gap-4">
              {filteredBarbers?.map((barber, index) => (
                <MiniBarberCard
                  key={index}
                  barbers={barber}
                  index={index}
                  onClick={() => onClick(barber)}
                />
              ))}
            </div>
          ) : (
            <p className="text-white font-bold">
              Frizerski salon nije pronađen za trazeni termin.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default BarbersList;
