import React, { useState } from "react";
import BarbersList from "./BarbersList";
import BarberModal from "./BarberModal";
import AppointmentCalnedar from "./AppointmentCalendar";
import { handleBarbersList } from "./Barbers";
import { saloonType } from "helperFunc/types";

const FormLayout: React.FC = () => {
  const [availableBarbers, setAvailableBarbers] = useState<any[]>([]);
  const [selectedBarber, setSelectedBarber] = useState<any>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [barbersList, setBarbersList] = useState<saloonType[]>([]);

  const filterAvailableBarbers = async (dateTime: Date) => {
    const selectedDay = dateTime.getDay();
    const selectedTime = dateTime.getHours() * 100 + dateTime.getMinutes();
    console.log(selectedDay, selectedTime)
    await handleBarbersList(setBarbersList);

    // const availableBarbers =  barbersList?.filter((barber: { availability: any[]; }) =>
    //   barber.availability.some(
    //     (slot: { day: number; start: string; end: string; }) =>
    //       slot.day === selectedDay &&
    //       parseInt(slot.start.replace(":", ""), 10) <= selectedTime &&
    //       parseInt(slot.end.replace(":", ""), 10) >= selectedTime
    //   )
    // );

    setAvailableBarbers(barbersList);
  };

  const handleBarberClick = (barber: any) => {
    console.log('ovde')
    setSelectedBarber(barber);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="bg-gray-800 flex justify-center items-start min-h-screen">
      <div className="flex flex-col my-20 mx-4 justify-center items-center relative w-full">
        <AppointmentCalnedar filterAvailableBarbers={filterAvailableBarbers} />
        {availableBarbers.length > 0 && (
          <BarbersList
            availableBarbers={availableBarbers}
            onClick={handleBarberClick}
          />
        )}
      </div>

      {showModal && selectedBarber && (
        <BarberModal
          onClick={handleCloseModal}
          selectedBarber={selectedBarber}
        />
      )}
    </div>
  );
};

export default FormLayout;
