

import axiosInstance from "axios-config"
import { saloonType } from "helperFunc/types";
import { Dispatch, SetStateAction } from "react";

export const handleBarbersList = async (setBarbersList:Dispatch<SetStateAction<saloonType[]>>)=>{
  try {
    const response = await axiosInstance.get('http://localhost:8000/saloon/',{
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      }
    })
    const data =  response.data.saloons
    setBarbersList(data)
  } catch (error) {
    console.log(error)
  }
}

