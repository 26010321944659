import axios from "axios";
import { logout } from "helperFunc/authFunctions";

const axiosInstance = axios.create({
  baseURL: process.env["REACT_APP_BACKEND_URL"],
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true, // To include cookies in requests
});


// Request interceptor to add the JWT token to headers
axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);




// Response interceptor to handle responses globally
async function refreshToken() {
  try {
    const response = await axiosInstance.post('http://localhost:8000/auth/refresh-token', {
      method: 'POST',
    });
    return response.data.token;
  } catch (error) {
    throw error;
  }
}

// Response interceptor to handle responses globally
axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const newToken = await refreshToken(); 
        localStorage.setItem('accessToken', newToken); 
        originalRequest.headers.Authorization = `Bearer ${newToken}`; 
        return axiosInstance(originalRequest); // Retry the original request
      } catch (refreshError) {
        localStorage.removeItem('accessToken');
        window.location.href = '/login';
        logout()
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);


export default axiosInstance;
