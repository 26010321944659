import React, { useState } from "react";
import Button from "../SharedComponents/Button";
import { useAuth } from "context/AuthContext";
import { handleSubmitLogin } from "helperFunc/authFunctions";

const Login = () => {
  const { handleLogin } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  return (
    <div className="h-screen font-lemon">
      <div className="flex flex-col items-center text-black">
        <div className="rounded-lg mt-60 p-8 bg-gray-700">
          <h2 className="text-2xl mb-4 text-white">Log In</h2>
          <form
            onSubmit={(e) => {
              handleSubmitLogin(
                e,
                email,
                password,
                setError,
                setSuccess,
                handleLogin
              );
            }}
            className="text-center"
          >
            <div className="mb-4">
              <label
                htmlFor="username"
                className="block mb-2 text-sm text-white"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-64 text-white p-2 border border-gray-500 rounded-lg bg-gray-800 focus:border-none focus:outline-none focus:ring-1 focus:ring-primary"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="password"
                className="block mb-2 text-sm text-white"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-64 text-white p-2 border border-gray-500 rounded-lg bg-gray-800 focus:border-none focus:outline-none focus:ring-1 focus:ring-primary"
                required
              />
            </div>
          <p className="text-[10px] text-white">Zaboravili ste lozinku? <strong className="cursor-pointer text-red-300">Promenite lozinku</strong></p>
            <Button position="mt-5" type="submit">
              Log In
            </Button>
          </form>
        </div>
        {error && <p className="text-red-300">{error}</p>}
        {success && <p className="text-green-300">Uspesno ste ulogovani :)</p>}
      </div>
    </div>
  );
};

export default Login;
