import React, { useRef, useState } from "react";
import Button from "../SharedComponents/Button";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import {
  handleInputChange,
  numberInputValidateNumber,
  togglePasswordVisibility,
  togglePasswordVisibilityRepeat,
} from "helperFunc/global";
import { handleSubmitSaloonRegister } from "helperFunc/saloonFunctions";
import { registerSaloonErrorType, registerSaloonType } from "helperFunc/types";
import { Link } from "react-router-dom";

const SaloonRegister = () => {
  const registerForm = document.getElementById(
    "registerForm"
  ) as HTMLFormElement;
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [showPassword, setShowPassword] = useState(true);
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(true);
  const [messageSucces, setMessageSucces] = useState("");
  const [messageError, setMessageError] = useState("");
  const cityRef = useRef(null);
  const townshipRef = useRef(null);
  const addressRef = useRef(null);
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const repeatPasswordRef = useRef(null);
  const mobileRef = useRef(null);
  const [errorObj, setErrorObj] = useState<registerSaloonErrorType>({
    profileDataErr: {
      cityErr: "",
      townshipErr: "",
      addressErr: "",
      nameErr: "",
    },
    emailErr: "",
    mobileNumberErr: "",
    passwordErr: "",
    repeatPasswordErr: "",
    profileModelErr: "",
  });

  const newSaloon = useRef<registerSaloonType>({
    profileData: {
      city: "",
      township: "",
      address: "",
      name: "",
    },
    email: "",
    mobileNumber: 555333,
    password: "",
    repeatPassword: "",
    profileModel: "SaloonProfile",
  });

  return (
    <div className="h-auto font-lemon">
      <div className="flex flex-col items-center text-white">
        <div className="rounded-lg my-24 p-8 bg-gray-700">
          <h2 className="text-xl md:text-2xl mb-8 text-white">
            Registruj frizerski salon
          </h2>
          <form
            onSubmit={(e) => {
              handleSubmitSaloonRegister(
                e,
                newSaloon,
                setPasswordsMatch,
                setMessageSucces,
                setMessageError,
                setErrorObj,
                registerForm
              );
            }}
            id="registerForm"
          >
            <div className="mb-4">
              <label htmlFor="name" className="block mb-2 text-sm text-white">
                Naziv Salona*
              </label>
              <input
                type="text"
                id="saloonName"
                ref={nameRef}
                className="w-full p-2 border border-gray-500 rounded-lg bg-gray-800 focus:border-none focus:outline-none focus:ring-1 focus:ring-primary"
                required
                onChange={(e) => {
                  handleInputChange(e, nameRef, newSaloon, "name");
                }}
              />
              {errorObj?.profileDataErr.nameErr && (
                <p className="text-red-500">
                  {errorObj?.profileDataErr.nameErr}
                </p>
              )}
            </div>
            <div className="mb-4 flex flex-col md:flex-row gap-4">
              <div>
                <label htmlFor="city" className="block mb-2 text-sm text-white">
                  Grad*
                </label>
                <input
                  type="text"
                  id="city"
                  ref={cityRef}
                  className="w-full md:w-64 p-2 border border-gray-500 rounded-lg bg-gray-800 focus:border-none focus:outline-none focus:ring-1 focus:ring-primary"
                  required
                  onChange={(e) => {
                    handleInputChange(e, cityRef, newSaloon, "city");
                  }}
                />
              </div>
              <div>
                <label
                  htmlFor="township"
                  className="block mb-2 text-sm text-white"
                >
                  Opština*
                </label>
                <input
                  type="text"
                  id="township"
                  ref={townshipRef}
                  className="w-full md:w-64 p-2 border border-gray-500 rounded-lg bg-gray-800 focus:border-none focus:outline-none focus:ring-1 focus:ring-primary"
                  required
                  onChange={(e) => {
                    handleInputChange(e, townshipRef, newSaloon, "township");
                  }}
                />
              </div>
            </div>
            <div className="mb-4">
              <label
                htmlFor="address"
                className="block mb-2 text-sm text-white"
              >
                Adresa*
              </label>
              <input
                type="text"
                id="address"
                ref={addressRef}
                className="w-full p-2 border border-gray-500 rounded-lg bg-gray-800 focus:border-none focus:outline-none focus:ring-1 focus:ring-primary"
                required
                onChange={(e) => {
                  handleInputChange(e, addressRef, newSaloon, "address");
                }}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block mb-2 text-sm text-white">
                Email Adresa*
              </label>
              <input
                type="email"
                id="email"
                ref={emailRef}
                className="w-full p-2 border border-gray-500 rounded-lg bg-gray-800 focus:border-none focus:outline-none focus:ring-1 focus:ring-primary"
                required
                onChange={(e) => {
                  handleInputChange(e, emailRef, newSaloon, "email");
                }}
              />
              {errorObj?.emailErr && (
                <p className="text-red-500">{errorObj?.emailErr}</p>
              )}
            </div>
            <div className="mb-4">
              <label
                htmlFor="mobileNumber"
                className="block mb-2 text-sm text-white"
              >
                Mobilni telefon*
              </label>
              <input
                type="tel"
                id="mobileNumber"
                ref={mobileRef}
                onChange={(e) => {
                  numberInputValidateNumber(e);
                  handleInputChange(e, mobileRef, newSaloon, "mobileNumber");
                }}
                className="w-full p-2 border border-gray-500 rounded-lg bg-gray-800 focus:border-none focus:outline-none focus:ring-1 focus:ring-primary"
                required
              />
              {errorObj?.mobileNumberErr && (
                <p className="text-red-500">{errorObj?.mobileNumberErr}</p>
              )}
            </div>
            <div className="mb-4">
              <label
                htmlFor="password"
                className="block mb-2 text-sm text-white"
              >
                Lozinka*
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "password" : "text"}
                  id="password"
                  ref={passwordRef}
                  onChange={(e) => {
                    handleInputChange(e, passwordRef, newSaloon, "password");
                  }}
                  className="w-full p-2 border border-gray-500 rounded-lg bg-gray-800 focus:border-none focus:outline-none focus:ring-1 focus:ring-primary"
                  required
                />
                <span
                  className="absolute right-3 top-[50%] transform -translate-y-1/2 cursor-pointer"
                  onClick={() => {
                    togglePasswordVisibility(setShowPassword, showPassword);
                  }}
                >
                  {showPassword ? (
                    <FaEyeSlash className="text-white" />
                  ) : (
                    <FaEye className="text-white" />
                  )}
                </span>
                {errorObj?.passwordErr && (
                  <p className="text-red-500">{errorObj?.passwordErr}</p>
                )}
              </div>
            </div>
            <div className="mb-4">
              <label
                htmlFor="confirmPassword"
                className="block mb-2 text-sm text-white"
              >
                Potvrdi Lozinku*
              </label>

              <div className="relative">
                <input
                  type={showPasswordRepeat ? "password" : "text"}
                  id="confirmPassword"
                  ref={repeatPasswordRef}
                  onChange={(e) => {
                    handleInputChange(
                      e,
                      repeatPasswordRef,
                      newSaloon,
                      "repeatPassword"
                    );
                  }}
                  className="w-full p-2 border border-gray-500 rounded-lg bg-gray-800 focus:border-none focus:outline-none focus:ring-1 focus:ring-primary"
                  required
                />
                <span
                  className="absolute right-3 top-[50%] transform -translate-y-1/2 cursor-pointer"
                  onClick={() => {
                    togglePasswordVisibilityRepeat(
                      setShowPasswordRepeat,
                      showPasswordRepeat
                    );
                  }}
                >
                  {showPasswordRepeat ? (
                    <FaEyeSlash className="text-white" />
                  ) : (
                    <FaEye className="text-white" />
                  )}
                </span>
              </div>
              {errorObj?.passwordErr && (
                <p className="text-red-500">{errorObj?.passwordErr}</p>
              )}
              {!passwordsMatch && (
                <p className="text-red-500 mt-4">Lozinke se ne poklapaju.</p>
              )}
            </div>

            {messageSucces && (
              <p className="text-green-500">Uspesna registracija!</p>
            )}
            {messageError && (
              <p className="text-red-500 whitespace-nowrap overflow-hidden overflow-ellipsis">
                Neuspesna registracija! <br /> Molimo Vas <br /> probajte
                ponovo!
              </p>
            )}
            <Button position="mt-4 w-full" type="submit" url="/register">
              Register
            </Button>
            <p className="text-center mt-2">
              Vec imate registrovani profil?{" "}
              <Link to={"/login"}>
                <b className="cursor-pointer">Ulogujte se</b>
              </Link>{" "}
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SaloonRegister;
