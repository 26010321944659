export async function verifyUserLoader({ request }) {
    const url = new URL(request.url);
    const searchParams = new URLSearchParams(url.search);
    const token = searchParams.get("token");
    try {
      const response = await fetch(`http://localhost:8000/verify?token=${token}`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      return data; // Return the fetched data
    } catch (error) {
      console.error("Error fetching verification data:", error);
      return null; // Return null or handle the error as needed
    }
  }
  