import React from "react";

interface ButtonProps {
  type: "submit" | "button" | "reset";
  url?: string;
  children: React.ReactNode;
  position?: string;
  click?: () => void;
}

const Button: React.FC<ButtonProps> = ({
  type,
  url,
  children,
  position,
  click,
}) => {
  return (
    <a href={url}>
      <button
        type={type}
        className={`bg-primary px-6 py-3 hover:scale-110 duration-300 text-white rounded-lg ${position}`}
        onClick={click}
      >
        <b>{children}</b>
      </button>
    </a>
  );
};

export default Button;
