import { useAuth } from "context/AuthContext";
import React, { useState } from "react";

interface saloonNavbarType {
  userData?: any;
}

const SaloonNavbar = ({ userData }: saloonNavbarType) => {
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const { logout } = useAuth();
  return (
    <div className="bg-gray-800 font-lemon top-0 w-full">
      <div className="mx-20 py-4 flex self-center flex-col md:flex-row gap-4 justify-between items-center">
        <a href="/" className="transition 3s hover:scale-110">
          <img
            src="/assets/logo_plavi.png"
            className="w-40"
            alt="Sisni Me Logo"
          />
        </a>

        <div
          className="relative "
          onBlur={() => {
            setDisplayDropdown(false);
          }}
          tabIndex={0}
        >
          <div className="relative">
            <div
              className="flex flex-col md:flex-row items-center rounded-lg justify-between cursor-pointer text-primary hover:bg-lightGray px-2 py-1"
              onClick={() => {
                setDisplayDropdown(!displayDropdown);
              }}
            >
              <img
                src="./assets/avatar.jpg"
                alt=""
                width={40}
                height={40}
                className="rounded-full mr-2"
              />
              <p>{userData?.email}</p>
            </div>
            <ul className="w-full  text-center">
              <li
                onClick={() => {
                  logout();
                }}
                className={`${
                  displayDropdown ? "flex" : "hidden"
                } px-1 rounded-lg justify-center cursor-pointer w-full py-2 text-white hover:bg-lightRed`}
              >
                Logout
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaloonNavbar;
