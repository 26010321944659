// AuthContext.js
import React, {
  createContext,
  useContext,
  useState,
  SetStateAction,
  Dispatch,
  ReactNode,
  useEffect,
} from "react";
import {
  handleLogin as handleLoginFunction,
  logout as logoutFunction,
} from "./../helperFunc/authFunctions";
import { jwtDecode } from "jwt-decode";


const AuthContext = createContext<AuthContextType | null>(null);

interface AuthContextType {
  isLoggedIn: boolean;
  handleLogin: (
    e: React.FormEvent<HTMLFormElement>,
    email: string,
    password: string,
    setError: Dispatch<SetStateAction<string>>,
    setSuccess: Dispatch<SetStateAction<string>>,
  ) => Promise<void>;
  logout: () => Promise<void>;
  profileModel: string;
  userData: any;
  token:string | null;
}

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [profileModel,setProfileModel] = useState("");
  const [userData, setUserData] = useState({});
  let token = localStorage.getItem('accessToken');
  const user =token &&  jwtDecode(token);

  useEffect(() => {
  if(user){
    setIsLoggedIn(true);
    setUserData(user)
  }
  }, [isLoggedIn])
  
 

  const handleLogin = async (
    e: React.FormEvent<HTMLFormElement>,
    email: string,
    password: string,
    setError: Dispatch<SetStateAction<string>>,
    setSuccess: Dispatch<SetStateAction<string>>,
    
  ) =>{
    try {
      //@ts-ignore
      const { user, profileModel } = await handleLoginFunction(
        e,
        email,
        password,
        setError,
        setSuccess,
      );
      if(profileModel){
        setProfileModel(profileModel)
      }
      if(user){
        setUserData(user)
        setIsLoggedIn(true)
      }
    } catch (error) {
      
    }
  }
    

  const logout = () => logoutFunction(setIsLoggedIn);

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, handleLogin, logout, profileModel, userData, token }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
