import React from "react";
import { useState } from "react";

interface AppointmentCalendarProps {
  filterAvailableBarbers: (dateTime: Date) => void;
}

const AppointmentCalnedar: React.FC<AppointmentCalendarProps> = ({
  filterAvailableBarbers,
}) => {
  const [selectedDate, setSelectedDate] = useState<string>("");
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const day = currentDate.getDate().toString().padStart(2, "0");
  const hours = currentDate.getHours().toString().padStart(2, "0");
  const minutes = currentDate.getMinutes().toString().padStart(2, "0");
  const formattedCurrentDate = `${currentYear}-${month}-${day}T${hours}:${minutes}`;

  const fiveDaysFromNow = new Date();
  fiveDaysFromNow.setDate(currentDate.getDate() + 5);
  const formattedFiveDaysFromNow = `${fiveDaysFromNow.getFullYear()}-${(
    fiveDaysFromNow.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${fiveDaysFromNow
    .getDate()
    .toString()
    .padStart(2, "0")}T23:59`;

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDateTime = new Date(e.target.value);
    setSelectedDate(e.target.value);
    filterAvailableBarbers(selectedDateTime);
  };

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  return (
    <>
      <p className="text-white pb-6 font-bold text-2xl">
        Izaberi termin za šišanje
      </p>
      <form onSubmit={handleFormSubmit} className="flex flex-col items-center">
        <input
          type="datetime-local"
          name="datetime"
          id="datetime"
          className="px-4 py-2 border border-transparent rounded-md focus:outline-none focus:border-primary text-white bg-gray-700"
          min={formattedCurrentDate}
          max={formattedFiveDaysFromNow}
          value={selectedDate}
          onChange={handleDateChange}
        />
      </form>
    </>
  );
};

export default AppointmentCalnedar;
