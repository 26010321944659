import { Dispatch, FormEvent, MutableRefObject, SetStateAction } from "react";
import { registerUserErrorType, registerUserType } from "./types";

export const handleSubmitUserRegister = async (
  e: FormEvent<HTMLFormElement>,
  newUser: MutableRefObject<registerUserType>,
  setPasswordsMatch: Dispatch<SetStateAction<boolean>>,
  setMessageSucces: Dispatch<SetStateAction<string>>,
  setMessageError: Dispatch<SetStateAction<string>>,
  setErrorObj: Dispatch<SetStateAction<registerUserErrorType>>,
  registerForm: HTMLFormElement | null
) => {
  e.preventDefault();
  const {
    profileData,
    email,
    password,
    repeatPassword,
    mobileNumber,
    profileModel,
  } = newUser.current;
  const newUserObject = {
    profileData,
    email,
    password,
    mobileNumber,
    profileModel,
  };

  if (password !== repeatPassword) {
    setPasswordsMatch(false);
    setErrorObj((prevErrorObj) => ({
      ...prevErrorObj,
      repeatPasswordErr: "Passwords do not match",
    }));
    return;
  }
  // let status:number;
  setErrorObj({
    profileDataErr: {
      firstNameErr: "",
      lastNameErr: "",
    },
    emailErr: "",
    mobileNumberErr: "",
    passwordErr: "",
    repeatPasswordErr: "",
    profileModelErr: "",
  });

  try {
    const response = await fetch(`http://localhost:8000/auth/signup`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newUserObject),
      credentials: "include",
    });
    const data = await response.json();

    if (data?.userId && data?.userId?.length > 0) {
      setErrorObj({
        profileDataErr: {
          firstNameErr: "",
          lastNameErr: "",
        },
        emailErr: "",
        mobileNumberErr: "",
        passwordErr: "",
        repeatPasswordErr: "",
        profileModelErr: "",
      });
      setMessageSucces(data.message);
      setMessageError("");
      registerForm && registerForm.reset();
      alert("Korisnik je uspesno registrovan!");
      window.location.href = "/zakazi";
    } else {
      const newErrorObj = data?.data?.reduce(
        (acc: registerUserErrorType, error: any) => {
          const profileDataFields = acc.profileDataErr
            ? Object.keys(acc.profileDataErr)
            : [];
          const isProfileDataField = profileDataFields.includes(error.path);

          if (isProfileDataField) {
            const key = error.path as keyof typeof acc.profileDataErr;
            acc.profileDataErr[key] = error?.msg;
          } else {
            const key = `${error.path}Err` as keyof registerUserErrorType;
            acc[key] = error?.msg;
          }

          return acc;
        },
        {
          profileDataErr: {
            firstNameErr: "",
            lastNameErr: "",
          },
          emailErr: "",
          mobileNumberErr: "",
          passwordErr: "",
          repeatPasswordErr: "",
          profileModelErr: "",
        }
      );
      setErrorObj(newErrorObj);
      setMessageError(data.message);
      setMessageSucces("");
    }
  } catch (error) {
    setPasswordsMatch(true);
    console.log(error);
  }
};
