
import { ChangeEvent, Dispatch, MutableRefObject, SetStateAction } from "react";

export const togglePasswordVisibility = (
  setShowPassword: Dispatch<SetStateAction<boolean>>,
  showPassword: boolean
) => {
  setShowPassword(!showPassword);
};

export const togglePasswordVisibilityRepeat = (
  setShowPasswordRepeat: Dispatch<SetStateAction<boolean>>,
  showPasswordRepeat: boolean
) => {
  setShowPasswordRepeat(!showPasswordRepeat);
};

export const handleInputChange = (
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  inputFieldRef: MutableRefObject<null>,
  newObjectRef: any,
  field: string
) => {
  if (inputFieldRef.current) {
    const profileDataExists =
      newObjectRef.current.profileData &&
      field in newObjectRef.current.profileData;

    if (profileDataExists) {
      newObjectRef.current = {
        ...newObjectRef.current,
        profileData: {
          ...newObjectRef.current.profileData,
          [field]: e.target.value,
        },
      };
    } else {
      newObjectRef.current = {
        ...newObjectRef.current,
        [field]: e.target.value,
      };
    }
  }
};

export const numberInputValidateNumber = (e: ChangeEvent<HTMLInputElement>) => {
  const inputValue = e.target.value;
  let sanitizedValue = inputValue.replace(/[^+\d]/g, "");
  if (sanitizedValue.indexOf("+") > 0) {
    sanitizedValue = sanitizedValue.replace(/\+/g, "");
  }
  e.target.value = sanitizedValue;
};