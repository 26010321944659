import Button from 'components/SharedComponents/Button';
import React, { useState } from 'react'

 const ResetPasswordPage = () => {
    const [error] = useState("");
    const [success] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [repeatedNewPassword, setRepeatedNewPassword] = useState("");


  return (
    <div className="h-screen font-lemon bg-gray-800">
    <div className="flex flex-col items-center text-black">
      <div className="rounded-lg mt-60 p-8 bg-gray-700">
        <form
          className="text-center"
        >
          <div className="mb-4">
            <label
              htmlFor="username"
              className="block mb-2 text-sm text-white"
            >
              Nova lozinka
            </label>
            <input
              type="email"
              id="email"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="w-64 text-white p-2 border border-gray-500 rounded-lg bg-gray-800 focus:border-none focus:outline-none focus:ring-1 focus:ring-primary"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block mb-2 text-sm text-white"
            >
              Ponovi lozinku
            </label>
            <input
              type="password"
              id="password"
              value={repeatedNewPassword}
              onChange={(e) => setRepeatedNewPassword(e.target.value)}
              className="w-64 text-white p-2 border border-gray-500 rounded-lg bg-gray-800 focus:border-none focus:outline-none focus:ring-1 focus:ring-primary"
              required
            />
          </div>
          <Button position="mt-5" type="submit">
            Promenite lozinku
          </Button>
        </form>
      </div>
      {error && <p className="text-red-300">{error}</p>}
      {success && <p className="text-green-300">Uspesno ste ulogovani :)</p>}
    </div>
  </div>
  )
}
export default ResetPasswordPage;