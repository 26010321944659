
import UserNavbar from 'components/SharedComponents/UserNavbar';
import { useAuth } from 'context/AuthContext';
import React from 'react'
import { Outlet } from 'react-router-dom'

const UserLayout = () => {
  const {userData}=useAuth()
  return (
    <div className='relative'>
    <UserNavbar userData={userData}/>
    <main>                
        <Outlet />
    </main>
    {/* <Footer /> */}
</div>
  )
}

export default UserLayout;