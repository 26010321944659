import { createBrowserRouter, Navigate, useLocation } from "react-router-dom";
import Home from "./../components/LandingPages/Home";
import Partner from "./../components/LandingPages/Partner";
import Login from "./../components/LoginAndRegister/Login";
import UserRegister from "./../components/LoginAndRegister/UserRegister";
import SaloonRegister from "./../components/LoginAndRegister/SaloonRegister";
import FormLayout from "./../components/ScheduleForm/FormLayout";
import AnonymousLayout from "./../layouts/AnonymousLayout";
import SaloonLayout from "./../layouts/SaloonLayout";
import ResetPasswordPage from './../components/LoginAndRegister/ResetPasswordPage.tsx'

import { useAuth } from "../context/AuthContext";
import UserLayout from "./../layouts/UserLayout";
import Profile from './../pages/Profile'
import ErrorPage from './../pages/404'
import  {
  verifyUserLoader,
} from "./../loaders/VerifyUser.js";
import VerifyUser from './../pages/VerifyUser.tsx'

const ProtectedRoute = ({ element }) => {
  const { isLoggedIn } = useAuth();
  setTimeout(() => {
    if (!isLoggedIn) {
      return <Navigate to="/login" />;
    }
  });

  return element;
};

const PreventLoginRegister = ({ element }) => {
  const { isLoggedIn } = useAuth();
  const location = useLocation();
  if (isLoggedIn && location.pathname==='/login') {
    return <Navigate to="/zakazi" />;
  }else if(isLoggedIn && location.pathname==='/napravi-nalog' || isLoggedIn && location.pathname==='/registruj-salon'){
    return <Navigate to="/profile" />;
  }

  return element;
};


const router = createBrowserRouter([
  {
    element: <AnonymousLayout />,
    children: [
      {
        path: "/partner",
        element: <Partner />,
      },
      {
        path: "/login",
        element:<PreventLoginRegister element={<Login />}/> ,
      },
      {
        path: "/napravi-nalog",
        element: <PreventLoginRegister element={<UserRegister />}/>,
      },
      {
        path: "/registruj-salon",
        element: <PreventLoginRegister element={<SaloonRegister />}/> ,
      },
      { path: "/", element: <Home /> },
    ],
  },

  {
    element: <UserLayout />,
    children: [
      {
        path: "/zakazi",
        element: <ProtectedRoute element={<FormLayout />} />,
      },
      {
        path:"/reset_password/:token",
        element: <ProtectedRoute element={<ResetPasswordPage />} />,
      },
      {
        path: "/verify",
        loader: verifyUserLoader,
        element: <VerifyUser />,
      },
    ],
  },
  {
    element: <SaloonLayout />,
    children: [
      {
        path: "/profile",
        element: <Profile />,
      },
    ],
  },
  {path: "*",
    element:<ErrorPage/>
  }
]);

export default router;
