
import AnonymusNavbar from './../components/SharedComponents/AnonymusNavbar'
import React from 'react'
import { Outlet } from 'react-router-dom'

const AnonymousLayout = () => {
  return (
    <div className='relative bg-mobilebackground xl:bg-background bg-cover'>
    <AnonymusNavbar/>
    <main>                
        <Outlet />
    </main>
    {/* <Footer /> */}
</div>
  )
}

export default AnonymousLayout