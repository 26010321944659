import React from "react";
import { Barber } from "./BarberTypes";

interface MiniBarberCardProps {
  barbers: Barber;
  index: number;
  onClick: () => void;
}

const MiniBarberCard: React.FC<MiniBarberCardProps> = ({
  barbers,
  index,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      key={index}
      className="bg-gray-800 px-6 py-4 rounded-md flex flex-row items-center gap-4 hover:scale-110 duration-300 hover:cursor-pointer"
    >
      <img src={barbers.slika} alt="barbers" className="w-12" />
      <div>
        <p className="text-white font-bold">{barbers.ime}</p>
        <p className="text-white text-xs">
          {`${barbers.lokacija.grad}, ${barbers.lokacija.opstina}, ${barbers.lokacija.adresa}`}
        </p>
      </div>
    </div>
  );
};

export default MiniBarberCard;
