// authFunctions.js
import { AxiosError } from "axios";
import axiosInstance from "axios-config";
import { jwtDecode } from "jwt-decode";
import { Dispatch, FormEvent, SetStateAction } from "react";

export const handleLogin = async (
  e: React.FormEvent<HTMLFormElement>,
  email: string,
  password: string,
  setError: Dispatch<SetStateAction<string>>,
  setSuccess: Dispatch<SetStateAction<string>>
) => {
  e.preventDefault();
  const newLoggedUser = {
    email,
    password,
  };
  try {
    const response = await axiosInstance.post(
      "http://localhost:8000/auth/login",
      newLoggedUser
    );
    const data = response.data;
    if (response.status !== 200) {
      setSuccess("");
      setError(data?.message);
      return { user: null, profileModel: null, token: null }; // Ensure return value
    } else if (data?.userId && data?.userId?.length > 0) {
      const user = jwtDecode(data?.token);

      //@ts-ignore
      if (user?.profileModel === "CustomerProfile") {
        localStorage.setItem("profileModel", "CustomerProfile");
        localStorage.setItem("accessToken", data?.token);
        setError("");
        setSuccess(data?.userId);
        window.location.href = '/zakazi'
        //@ts-ignore
      } else if (user?.profileModel === "SaloonProfile") {
        localStorage.setItem("profileModel", "SaloonProfile");
        localStorage.setItem("accessToken", data?.token);
        setError("");
        setSuccess(data?.userId);
        window.location.href = '/profile'
        
      } else {
        setError("Invalid profile model.");
        return { user: null, profileModel: null, token: null };
      }
      //@ts-ignore
      return { user, profileModel: user?.profileModel, token: data?.token };
    }
    return { user: null, profileModel: null, token: null };
  } catch (error) {
    const axiosError = error as AxiosError;
    if (axiosError.response) {
      const responseData = axiosError.response.data as { message: string };
      setError(responseData.message);
      return { user: null, profileModel: null, token: null }; // Ensure return value
    } else {
      // Handle other errors
      setError("An error occurred during login. Please try again.");
      return { user: null, profileModel: null, token: null }; // Ensure return value
    }
  }
};

export const logout = async (
  setIsLoggedIn?: Dispatch<SetStateAction<boolean>>
) => {
  try {
    const response = await axiosInstance.delete(
      "http://localhost:8000/auth/logout",
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    if (response.status === 200) {
      setIsLoggedIn && setIsLoggedIn(false);
      localStorage.removeItem("accessToken");
      window.location.href = "/login";
    } else {
      const errorData = await response.data;
      console.error("Logout failed:", errorData.message);
    }
  } catch (error) {
    console.error("An error occurred during logout:", error);
  }
};

export const handleSubmitLogin = async (
  e: React.FormEvent<HTMLFormElement>,
  email: string,
  password: string,
  setError: Dispatch<SetStateAction<string>>,
  setSuccess: Dispatch<SetStateAction<string>>,
  handleLogin: {
    (
      e: FormEvent<HTMLFormElement>,
      email: string,
      password: string,
      setError: Dispatch<SetStateAction<string>>,
      setSuccess: Dispatch<SetStateAction<string>>
    ): Promise<void>;
    (
      arg0: FormEvent<HTMLFormElement>,
      arg1: any,
      arg2: any,
      arg3: any,
      arg4: any
    ): any;
  }
) => {
  e.preventDefault();
  try {
    await handleLogin(e, email, password, setError, setSuccess);
  } catch (error) {
    console.log(error);
  }
};
