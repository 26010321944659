import { Dispatch, FormEvent, MutableRefObject, SetStateAction } from "react";
import { registerSaloonErrorType, registerSaloonType } from "./types";

export const handleSubmitSaloonRegister = async (
  e: FormEvent<HTMLFormElement>,
  newSaloon: MutableRefObject<registerSaloonType>,
  setPasswordsMatch: Dispatch<SetStateAction<boolean>>,
  setMessageSucces: Dispatch<SetStateAction<string>>,
  setMessageError: Dispatch<SetStateAction<string>>,
  setErrorObj: Dispatch<SetStateAction<registerSaloonErrorType>>,
  registerForm: HTMLFormElement | null
) => {
  e.preventDefault();
  const {
    profileData,
    email,
    password,
    repeatPassword,
    mobileNumber,
    profileModel,
  } = newSaloon.current;
  const newSaloonObject = {
    profileData,
    email,
    password,
    mobileNumber,
    profileModel,
  };

  if (password !== repeatPassword) {
    setPasswordsMatch(false);
    setErrorObj((prevErrorObj) => ({
      ...prevErrorObj,
      repeatPasswordErr: "Passwords do not match",
    }));
    return;
  }

  setErrorObj({
    profileDataErr: {
      cityErr: "",
      townshipErr: "",
      addressErr: "",
      nameErr: "",
    },
    emailErr: "",
    mobileNumberErr: "",
    passwordErr: "",
    repeatPasswordErr: "",
    profileModelErr: "",
  });

  try {
    const response = await fetch(`http://localhost:8000/auth/signup`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newSaloonObject),
      credentials: "include",
    });
    const data = await response.json();
    if (data?.userId && data?.userId?.length > 0) {
      setPasswordsMatch(true);
      setErrorObj({
        profileDataErr: {
          cityErr: "",
          townshipErr: "",
          addressErr: "",
          nameErr: "",
        },
        emailErr: "",
        mobileNumberErr: "",
        passwordErr: "",
        repeatPasswordErr: "",
        profileModelErr: "",
      });
      setMessageSucces(data.message);
      setMessageError("");
      registerForm && registerForm.reset();
      setMessageError("");
      alert("Uspesno ste registrovali salon!");
      window.location.href = "/zakazi";
    } else {
      const newErrorObj = data?.data?.reduce(
        (acc: registerSaloonErrorType, error: any) => {
          const profileDataFields = acc.profileDataErr
            ? Object.keys(acc.profileDataErr)
            : [];
          const isProfileDataField = profileDataFields.includes(error.path);

          if (isProfileDataField) {
            const key = error.path as keyof typeof acc.profileDataErr;
            acc.profileDataErr[key] = error?.msg;
          } else {
            const key = `${error.path}Err` as keyof registerSaloonErrorType;
            acc[key] = error?.msg;
          }

          return acc;
        },
        {
          profileDataErr: {
            cityErr: "",
            townshipErr: "",
            addressErr: "",
            saloonNameErr: "",
          },
          emailErr: "",
          mobileNumberErr: "",
          passwordErr: "",
          repeatPasswordErr: "",
          profileModelErr: "",
        }
      );

      setErrorObj(newErrorObj);
      setMessageError(data.message);
      setMessageSucces("");
    }
  } catch (error) {
    setPasswordsMatch(true);
    console.log(error);
  }
};
