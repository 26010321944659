import React from "react";
import { FaEdit } from "react-icons/fa";

const Profile = () => {
  const salonInfo = {
    naziv: "sisni.me",
    grad: "Beograd",
    opstina: "Vozdovac",
    adresa: "Neka Ulica 10a",
    radnoVreme: [
      { radnimDanima: "09:00 - 21:00" },
      { subotom: "10:00 - 18:00" },
    ],
    email: "shishnime@gmail.com",
    telefon: "+3812345678",
    koordinate: "",
  };

  const termini = [
    {
      termin: "09:15 - 10:00",
      vremeTrajanja: "45min",
      moguceTrajanje: "60min",
      status: "slobodno",
    },
    {
      termin: "10:05 - 11:05",
      vremeTrajanja: "60min",
      status: "zakazano",
    },
  ];

  const radnimDanima = salonInfo.radnoVreme.find(
    (item) => item.radnimDanima
  )?.radnimDanima;
  const subotom = salonInfo.radnoVreme.find((item) => item.subotom)?.subotom;

  return (
    <div className="bg-gray-800 flex justify-start items-center flex-col min-h-screen font-lemon">
      <div className="w-fit mt-0 md:mt-20 px-6 py-4 flex justify-center items-start flex-col md:flex-row mx-10 gap-6">
        <img
          src="./assets/sisni-me-logo-blue-background.png"
          alt="Frizerski salon"
          className="w-96 rounded-lg"
        />
        <div className="text-white">
          <h1 className="text-2xl text-primary uppercase">{salonInfo.naziv}</h1>
          <ul className="font-sans font-bold">
            <li>
              Adresa: {salonInfo.grad}, {salonInfo.opstina}, {salonInfo.adresa}
            </li>
            <li>
              Radno vreme:
              <ul className="list-style-type-disc">
                <li>- Radnim danima: {radnimDanima}</li>
                <li>- Subotom: {subotom}</li>
              </ul>
            </li>
            <li>
              Email:{" "}
              <a
                href={`mailto:${salonInfo.email}`}
                className="hover:text-primary"
              >
                {salonInfo.email}
              </a>
            </li>
            <li>Telefon: {salonInfo.telefon}</li>
          </ul>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d181139.85102770833!2d20.422596999999996!3d44.81524535!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a7aa3d7b53fbd%3A0x1db8645cf2177ee4!2sBelgrade!5e0!3m2!1sen!2srs!4v1716589659889!5m2!1sen!2srs"
            title={`Barber Details`}
            className="w-[325px] h-[250px] md:w-full md:h-[180px] border-0 mt-6"
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <FaEdit className="text-xl text-white" />
      </div>
      <div className="container mx-auto px-4 sm:px-8">
        <div className="py-8">
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
              <table className="min-w-full leading-normal font-sans font-bold">
                <thead>
                  <tr>
                    <th className="px-5 py-3  bg-gray-700 text-left text-xs font-semibold text-white uppercase tracking-wider">
                      Termini
                    </th>
                    <th className="px-5 py-3  bg-gray-700 text-left text-xs font-semibold text-white uppercase tracking-wider">
                      Trajanje termina
                    </th>
                    <th className="px-5 py-3  bg-gray-700 text-left text-xs font-semibold text-white uppercase tracking-wider">
                      Status
                    </th>
                    <th className="px-5 py-3  bg-gray-700"></th>
                  </tr>
                </thead>
                <tbody>
                  {termini.map((termin, index) => (
                    <tr>
                      <td
                        className={`px-5 py-5 ${
                          index % 2 === 0 ? " bg-gray-600" : "bg-gray-500"
                        } text-sm`}
                      >
                        <div className="flex">
                          <div className="ml-3">
                            <p className="text-white whitespace-no-wrap">
                              {termin.termin}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td
                        className={`px-5 py-5 ${
                          index % 2 === 0 ? " bg-gray-600" : "bg-gray-500"
                        } text-sm`}
                      >
                        <p className="text-white whitespace-no-wrap">
                          {termin.vremeTrajanja}
                        </p>
                        {termin.moguceTrajanje && (
                          <p className="text-gray-800 whitespace-no-wrap">
                            Moguće trajanje {termin.moguceTrajanje}
                          </p>
                        )}
                      </td>
                      <td
                        className={`px-5 py-5 ${
                          index % 2 === 0 ? " bg-gray-600" : "bg-gray-500"
                        } text-sm`}
                      >
                        <span
                          className={`relative inline-block px-3 py-1 font-semibold ${
                            termin.status === "slobodno"
                              ? "text-green-900"
                              : "text-red-900"
                          }  leading-tight`}
                        >
                          <span
                            aria-hidden
                            className={`absolute inset-0 ${
                              termin.status === "slobodno"
                                ? "bg-green-300"
                                : "bg-red-300"
                            } opacity-50 rounded-md`}
                          ></span>
                          <span className="relative small-caps">
                            {termin.status}
                          </span>
                        </span>
                      </td>
                      <td
                        className={`px-5 py-5 ${
                          index % 2 === 0 ? " bg-gray-600" : "bg-gray-500"
                        } text-sm text-right`}
                      >
                        <button
                          type="button"
                          className="inline-block text-gray-200 hover:text-gray-400 text-xl"
                        >
                          <FaEdit />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
