import React from "react";
import Button from "components/SharedComponents/Button";
import { Barber } from "./BarberTypes";

interface BarberModalProps {
  selectedBarber: Barber;
  onClick: () => void;
}

const BarberModal: React.FC<BarberModalProps> = ({
  onClick,
  selectedBarber,
}) => {
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto flex justify-center items-center ">
      <div className="bg-gray-700 bg-opacity-75 absolute inset-0"></div>
      <div className="z-20 bg-gray-800 p-8 rounded-md px-6 py-4">
        <button
          onClick={onClick}
          className="relative top-0 left-[95%] text-xl mt-2 text-white hover:opacity-70 duration-300"
        >
          <b>&#x2715;</b>
        </button>
        <div className="flex flex-row gap-4 md:gap-6 items-start justify-start">
          <img src="/assets/frizer.png" alt="Frizer" className="w-24 md:w-32" />
          <div>
            <h2 className="text-xl md:text-2xl font-bold md:mb-4 text-white mt-4">
              {selectedBarber.ime}
            </h2>
            <p className="text-sm md:text-base text-white">{`${selectedBarber.lokacija.grad}, ${selectedBarber.lokacija.opstina}, ${selectedBarber.lokacija.adresa}`}</p>
          </div>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d181139.85102770833!2d20.422596999999996!3d44.81524535!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a7aa3d7b53fbd%3A0x1db8645cf2177ee4!2sBelgrade!5e0!3m2!1sen!2srs!4v1716589659889!5m2!1sen!2srs"
          title={`Barber Details - ${selectedBarber.ime}`}
          className="w-[325px] h-[250px] md:w-[435px] md:h-[350px] border-0 mt-6"
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        <Button position="mt-6 mb-2 w-full" type="submit">
          Zakaži
        </Button>
      </div>
    </div>
  );
};

export default BarberModal;
