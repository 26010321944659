import React from "react";
// import Navbar from "../SharedComponents/Navbar";

const Partner = () => {
  return (
    <div className="h-full bg-mobilebackground xl:bg-background bg:cover font-lemon">
      {/* <Navbar /> */}
      <div className="font-sans max-w-[1140px] pb-20 mx-auto text-white flex flex-col pl-10 lg:pl-0 lg:justify-center gap-8 lg:items-center pt-20">
        <h1 className="font-lemon text-xl lg:text-2xl pt-4 md:pt-10">
          Zašto bi trebali da postanete Šišni Me Partner?
        </h1>
        <p className="pt-4 lg:mx-20 mr-6">
          Šišanje je oduvek bilo neophodno za održavanje čiste i uredne frizure.
          Međutim, način na koji zakazujemo termine za frizerske usluge se
          menjao tokom vremena. Danas živimo u digitalnom dobu, a postoji niz
          razloga zbog kojih je zakazivanje šišanja online bolja opcija nego
          putem telefonskog poziva:
        </p>
        <ul className="list-disc">
          <li className="lg:ml-20 mr-6">
            Online zakazivanje nudi veću praktičnost. U današnjem brzom načinu
            života, sve više ljudi nema vremena za duge razgovore i čekanje na
            telefonskim linijama. Putem online rezervacija, klijenti mogu
            zakazati termin kad god im odgovara, bilo da je to tokom radnog
            vremena ili kasno uveče. Ovo čini proces mnogo efikasnijim i
            korisnijim za klijente.
          </li>
          <li className="lg:ml-20 mr-6 pt-4">
            Tačnost i preciznost. Kada zakazujete putem telefona, postoji šansa
            za nesporazum između klijenta i frizera. Može doći do grešaka u
            komunikaciji ili zaboravljanja termina.
          </li>
          <li className="lg:ml-20 mr-6 pt-4">
            Online rezervacije omogućavaju klijentima da pregledaju
            raspoloživost frizera.
          </li>
          <li className="lg:ml-20 mr-6 pt-4">
            Napredne funkcionalnosti online platformi takođe doprinose boljoj
            kontroli. Klijenti mogu lako promeniti ili otkazati termine bez
            potrebe za pozivanjem i menjanjem svojih planova. Ovo je posebno
            korisno u slučajevima hitnih obaveza ili nepredviđenih promena u
            rasporedu.
          </li>
          <li className="lg:ml-20 mr-6 pt-4">
            Osim navedenih prednosti online zakazivanja šišanja za klijente,
            postoji i važan faktor koji olakšava frizerima obavljanje njihovog
            posla - izbegavanje telefonskih poziva tokom šišanja.
          </li>
          <li className="lg:ml-20 mr-6 pt-4">
            Kada frizeri smanje upotrebu telefona tokom radnog dana, mogu se
            potpuno posvetiti svojim klijentima. Prekidi u obliku telefonskih
            poziva mogu biti veoma ometajući, ne samo za klijente koji se
            tretiraju, već i za frizera koji pokušava pružiti najbolju moguću
            uslugu. Online zakazivanje eliminiše potrebu za neprestanim
            odgovaranjem na pozive i pomaže frizerima da ostanu fokusirani na
            svoj posao.
          </li>
          <li className="lg:ml-20 mr-6 pt-4">
            Pored toga, online zakazivanje takođe olakšava frizerima praćenje i
            upravljanje svojim klijentima. Mogu lako pristupiti informacijama o
            zakazanim terminima, preferencama klijenata i njihovoj istoriji
            usluga, čime se poboljšava kvalitet usluge i stvara bolji odnos sa
            klijentima.
          </li>
        </ul>
        <p className="pt-4 lg:mx-20 mr-6">
          Sve u svemu, prelazak na online zakazivanje šišanja ne samo da pruža
          brojne prednosti klijentima već takođe pomaže frizerima da se bolje
          koncentrišu na svoj posao i pruže kvalitetniju uslugu, čime se
          stvaraju zadovoljni i verni klijenti. Ovo je win-win situacija za obe
          strane u frizerskom salonu.
        </p>
      </div>
    </div>
  );
};

export default Partner;
